#header-nav {
    font-size: 1.25rem;
    transition: 0.5s;
    padding-top: 0;
    padding-bottom: 0;

    @include media-breakpoint-up(lg) {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    @include media-breakpoint-up(xl) {
        .navbar-brand {
            position: absolute;
        }
    }

    &.stuck {
        padding-top: 0;
        padding-bottom: 0;

        @include media-breakpoint-up(lg) {
            img {
                min-width: 40px !important;
                width: 40px;
                transition: all 0.5s ease-in-out;
            }
        }
    }

    &.unstuck {
        img {
            transition: all 0.5s ease-in-out;
        }
    }
}
