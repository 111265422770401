.page-home {
    .banner {
        min-height: 40vh;

        h1 {
            font-size: 2rem;
            font-weight: bold;

            @include media-breakpoint-up(sm) {
                font-size: 3rem;
            }

            @include media-breakpoint-up(md) {
                font-size: 4rem;
            }
        }

        h2 {
            font-size: 1.5rem;

            @include media-breakpoint-up(sm) {
                font-size: 1.75rem;
            }
        }
    }
}
