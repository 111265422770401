//
// Footer
//

footer {
    padding: 4rem 0 5rem 0;

    .btn-link {
        display: block;
        text-decoration: none;

        @include media-breakpoint-up(md) {
            text-align: left;
        }
    }

    .slogan {
        display: flex;
        p {
            align-self: flex-end;
        }
    }
}
