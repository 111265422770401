$pill-font-size: 1.4rem;
$approach-margin-md-start: 0.1rem;
$approach-margin-md-end: -0.5rem;

.approaches {
    position: relative;

    .pill {
        text-align: center;
        top: -2rem;
        left: 0;
        border-radius: 1rem;
        background-color: #114c69;
        position: absolute;
        padding: 1.2rem 1rem 1rem 1rem;
        min-height: 4rem;
        width: fit-content;
        color: #ffffff;
        font-size: $pill-font-size;
        line-height: $pill-font-size;

        @include media-breakpoint-down(md) {
            left: 1rem;
            top: -1.6rem;
            max-width: 80%;
            font-size: $pill-font-size * 0.9;
            line-height: $pill-font-size * 0.9;
            min-height: 3rem;
            padding: 0.8rem 0.8rem 0.8rem 0.8rem;
        }
    }

    .pill::before {
        content: '';
        position: absolute;
        border-radius: 1rem;
        top: 0.8rem;
        left: 0.8rem;
        z-index: -1 !important;
        background-color: #000000;
        opacity: 0.3;
        padding: 1.2rem 1rem 1rem 1rem;
        height: 100%;
        font-size: $pill-font-size;
        line-height: $pill-font-size;
        width: 100%;
    }

    .end-button {
        z-index: 10 !important;
        bottom: -2rem;
        right: 0;
        position: absolute;
        width: fit-content;
    }

    .start-button {
        z-index: 10 !important;
        bottom: -2rem;
        left: 0;
        position: absolute;
        width: fit-content;
    }

    .approach-right {
        .pill {
            right: 0;
            left: auto;

            @include media-breakpoint-down(md) {
                right: 1rem;
            }
        }

        .arrow {
            right: 75%;

            border-left: 1rem solid transparent;
            border-top: 1rem solid transparent;
            border-bottom: 1rem solid transparent;
            border-right: 1rem solid $black;
        }
    }

    .arrow {
        top: -1.1rem;
        right: 20%;
        position: absolute;
        width: 0px;
        height: 0px;
        border-top: 1rem solid transparent;
        border-bottom: 1rem solid transparent;
        border-left: 1rem solid $black;
        z-index: -1;

        @include media-breakpoint-down(md) {
            right: 5%;
        }
    }

    .approach {
        position: relative;

        padding-top: 2rem;
        padding-bottom: 2rem;
        border: 4px solid #000000;
        margin-top: -0.25rem;

        border-radius: 120px;
        @include media-breakpoint-down(lg) {
            border-radius: 100px;
        }
        @include media-breakpoint-down(md) {
            border-radius: 10px;
            border: 3px solid #000000;
            margin-top: -0.2rem;
        }
        z-index: 0;
    }

    .approach:nth-child(odd) {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        @include media-breakpoint-down(md) {
            margin-left: $approach-margin-md-start;
            margin-right: $approach-margin-md-end;
        }
    }

    .approach:nth-child(even) {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        @include media-breakpoint-down(md) {
            margin-right: $approach-margin-md-start;
            margin-left: $approach-margin-md-end;
        }
    }

    .approach-links {
        padding-top: 1rem;
        padding-bottom: 4rem;
        font-size: large;
    }
}
