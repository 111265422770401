.app-layout {
    background-color: $background-color;

    section.splash {
        width: 100%;
        height: 100%;
        min-height: 70vh;

        background-position: center;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: cover;

        padding-top: 8rem;
        padding-bottom: 5rem;
        vertical-align: middle;

        h1.splash {
            font-size: 10rem;
            font-family: $font-family-special;
        }

        @include media-breakpoint-down(md) {
            h1.splash {
                font-size: 5rem;
            }
        }
    }

    :is(h2, h3, h4, h5, h6):not(:first-child) {
        padding-top: 1.5rem;
    }

    section {
        padding-top: 2rem;
        padding-bottom: 2rem;
        background-color: $white;
        color: $black;
    }

    section:last-of-type {
        padding-bottom: 5rem;
    }

    section.bg-alternate {
        background-color: #001d20;
        color: $white;

        .btn-link {
            color: $light;
        }
    }

    section.bg-transparent {
        background-color: unset;
    }
}
