$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
);

$btn-border-radius: 2rem;
$btn-padding-x: 1.5rem;
$btn-padding-y: 1.25rem;
$btn-box-shadow: 0.1875rem 0.1875rem 0.25rem 0px rgba(0, 0, 0, 0.35) !important;
$btn-focus-box-shadow: 0 0 0 0.25rem rgb(22 23 94 / 49%);
$btn-on-dark-focus-box-shadow: 0 0 0 0.25rem rgb(198 176 215 / 64%);
$btn-focus-width: 2px;

$btn-border-radius-sm: 2rem;
$btn-padding-x-sm: 1.5rem;
$btn-padding-y-sm: 1.25rem;

$btn-border-radius-lg: 2rem;
$btn-padding-x-lg: 1.5rem;
$btn-padding-y-lg: 1.25rem;

$navbar-brand-font-size: 1.5rem;
$navbar-padding-y: 1.5rem;
$navbar-nav-link-padding-x: 0.75rem;
