.me-page {
    .name {
        margin-bottom: 0;
    }
    .role {
        font-size: 1.2rem;
    }

    .links {
        .btn.btn-link {
            font-weight: normal;
        }
    }
    .profile-picture {
        margin: auto;
        img {
            max-width: 18rem;
            border-radius: 50% !important;
        }
    }
}
