.services {
    .service {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .service-left {
        background-color: $brand-light;
    }

    .service-right {
        .title-section {
            @include media-breakpoint-down(lg) {
                flex-direction: column-reverse;
            }
        }
    }

    .service-links {
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-size: large;
    }
}

@each $color, $value in $brand-colors {
    .service-bg-#{$color} {
        background-color: $value !important;
    }
}
