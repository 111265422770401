$input-height-xl: add($input-line-height * 1em, add($input-padding-y-lg, $input-height-border, false)) !default;

$input-padding-y-xl: $input-padding-y-lg;
$input-padding-x-xl: $input-padding-x-lg;
$input-font-size-xl: $input-font-size-lg * 2;
$input-border-radius-xl: $input-border-radius-lg;

.form-control-xl {
    min-height: $input-height-xl;
    padding: $input-padding-y-xl $input-padding-x-xl;
    @include font-size($input-font-size-xl);
    @include border-radius($input-border-radius-xl);

    &::file-selector-button {
        padding: $input-padding-y-xl $input-padding-x-xl;
        margin: (-$input-padding-y-xl) (-$input-padding-x-xl);
        margin-inline-end: $input-padding-x-xl;
    }

    &::-webkit-file-upload-button {
        padding: $input-padding-y-xl $input-padding-x-xl;
        margin: (-$input-padding-y-xl) (-$input-padding-x-xl);
        margin-inline-end: $input-padding-x-xl;
    }
}

.input-group.fancy-radio {
    display: block;
    margin: 1rem 0;
    position: relative;

    .form-check {
        padding-left: 0;
    }

    .form-feedback {
        margin-top: $form-feedback-margin-top;
        font-size: $form-feedback-font-size;
        font-style: $form-feedback-font-style;

        &.valid {
            color: $form-feedback-valid-color;
        }
        &.invalid {
            color: $form-feedback-invalid-color;
        }
    }

    label {
        padding: 1rem 2rem;
        width: 100%;
        display: block;
        text-align: left;
        cursor: pointer;
        position: relative;
        z-index: 2;
        transition: color 200ms ease-in;
        overflow: hidden;
        border-radius: $btn-border-radius;
        border: $input-border-width solid #ced4da;

        &:after {
            width: 32px;
            height: 32px;
            content: '';
            border: 2px solid #d1d7dc;
            background-color: #fff;
            background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
            background-repeat: no-repeat;
            background-position: 2px 3px;
            border-radius: 50%;
            z-index: 2;
            position: absolute;
            right: 2rem;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            transition: all 200ms ease-in;
        }
    }

    input:checked ~ label {
        color: #fff;
        background-color: $secondary;
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);

        &:after {
            background-color: $primary;
            border-color: $primary;
        }
    }

    input {
        width: 32px;
        height: 32px;
        order: 1;
        z-index: 2;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        visibility: hidden;
    }
}

.big-radio {
    display: block;
    margin: 1rem 0;
    position: relative;

    .form-check {
        padding-left: 0;
        border-radius: $btn-border-radius;
        position: relative;

        &:focus-within {
            box-shadow: 0 0 0 0.25rem rgba(95, 69, 115, 0.25);
        }
    }

    .form-feedback {
        margin-top: $form-feedback-margin-top;
        font-size: $form-feedback-font-size;
        font-style: $form-feedback-font-style;

        &.valid {
            color: $form-feedback-valid-color;
        }
        &.invalid {
            color: $form-feedback-invalid-color;
        }
    }

    label {
        padding: 1rem 4rem 1rem 2rem;
        width: 100%;
        display: block;
        text-align: left;
        cursor: pointer;
        position: relative;
        z-index: 1;
        transition: color 200ms ease-in;
        overflow: hidden;
        border-radius: $btn-border-radius;
        border: $input-border-width solid #ced4da;

        &:focus-within {
            box-shadow: 0 0 0 0.25rem rgba(95, 69, 115, 0.25);
        }
    }

    input:checked ~ label {
        color: #fff;
        background-color: $secondary;
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);

        border-color: $primary;
    }

    input {
        width: 32px;
        height: 32px;
        content: '';
        border: 2px solid #d1d7dc;
        background-color: #fff;
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ") !important;
        background-repeat: no-repeat !important;
        background-position: 3px 5px !important;
        border-radius: 50%;
        z-index: 2;
        position: absolute;
        right: 2rem;
        top: 50%;
        transform: translateY(-62%);
        bottom: 0;
        cursor: pointer;
        transition: all 200ms ease-in;
    }
}
