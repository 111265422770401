//
// Colors
//

// Grayscale

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

// Colors

$teal: #64a19d;
$red: #a16468;
$purple: #7464a1;
$yellow: #e4c662;
$green: #67c29c;
$cyan: #1cabc4;

$light: $gray-100;
$dark: $gray-900;

$success: #80e37d;
$info: #aeddfe;
$warning: #edf060;
$danger: #a5402d;

$primary: #003c58;
$secondary: #6968a6;

$brand-primary: #00a6fb;
$brand-secondary: #1d70b8;
$brand-tertiary: #1bc5bd;
$brand-alternate: #ffdb30;

$brand-dark-accent: #f18701;
$brand-dark-shades: #1e1e2d;
$brand-bold: #d24727;
$brand-mid: #eef0f8;
$brand-light: #f0f0f0;
$brand-dark: #010101;

// Bootstrap color map
$theme-colors: (
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
);

$brand-colors: (
    'primary': $brand-primary,
    'secondary': $brand-secondary,
    'alternate': $brand-alternate,
    'alternate-mid': $brand-dark-accent,
    'alternate-harsh': $brand-bold,
    'light': $brand-light,
    'dark': $brand-dark,
);

$section-colors: (
    'primary': $brand-primary,
    'secondary': $brand-secondary,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $brand-colors);

$link-color: $primary;

$min-contrast-ratio: 2.5;

$background-color: #ffffff;
