//
// Buttons
//

.btn {
    box-shadow: 0.1875rem 0.1875rem 0.25rem 0px rgba(0, 0, 0, 0.35);

    &:focus {
        box-shadow: $btn-focus-box-shadow;
        outline: 0;
    }

    &.no-round-edges {
        border-radius: 0;
    }
}

.btn-xs {
    padding: $btn-padding-y * 0.5 $btn-padding-x;
    font-size: $btn-font-size * 0.8;
}

.btn.on-dark {
    &:focus {
        box-shadow: $btn-on-dark-focus-box-shadow;
    }
}

.btn.btn-link {
    vertical-align: baseline;
    padding: 0;
    box-shadow: none;
    font-weight: bold;
    font-size: $btn-font-size;

    border-radius: 0 !important;

    &:focus {
        box-shadow: 0;
        outline: auto;
    }
}

.btn.btn-link.no-decoration {
    text-decoration: none !important;
}

.btn.btn-link.light {
    color: $light;
}

.btn.btn-dark {
    &:hover {
        background-color: #343434;
        border-color: #2f2f2f;
    }
}
