.card-button {
    height: 100%;

    &.linked {
        cursor: pointer;
        .card {
            &:hover {
                background-color: #ecf0ff;
            }
        }
    }

    .card {
        height: 100%;
    }

    .floating-button {
        bottom: 1rem;
        position: absolute;
    }

    .card-body {
        padding-bottom: 5.5rem;
    }

    .justify-right {
        .floating-button {
            right: 2rem;
        }
    }

    .justify-center {
        text-align: center;

        .floating-button {
            transform: translate(-50%, 0);
        }
    }
}
