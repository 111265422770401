//
// Global theme styling
//

html {
    scroll-padding-top: calc(4.5rem - 1px);
    box-sizing: border-box;
    background-color: $background-color;
}

body {
    // letter-spacing: $base-letter-spacing;
    background-color: $background-color;
}
