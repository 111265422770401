.profile {
    text-align: center;
    .name {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }

    .role {
        font-style: bold;
    }
}
